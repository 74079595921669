<template>
  <div>
    <h5 class="pb-2">Equipamentos</h5>
    <div class="_main">
      <CCol sm="12" md="12" class="_no-padding">
        <CCard class="_card-container">
          <CCardHeader class="_card-header-parent mt-2" v-if="pages.length !== 0">
            <div>
              <strong class="mr-2">Trilha:</strong>                        
              <CButton v-for="(item, index) in pages" :key="index" size="sm" color="blue-dark" class="_btn-parent" @click="reset(index)">
                <i class="far fa-dot-circle mr-2"></i> {{item.descriptionKey}}
              </CButton>         
            </div>
          </CCardHeader>
          <CCardBody class="_card-body">
            <Equipment v-if="pages.length === 0" @set-hierarchy-level="hierarchyLevel = $event"></Equipment>
            <div class="_main-parent">
              <CTabs :active-tab.sync="activeTab" v-model="activeTab">
                <!-- Nivel 01 -->
                <CTab v-show="activeLevel===1">
                  <template slot="title">Equipamento Vinculado </template>
                  <EquipmentAssociate :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></EquipmentAssociate>
                </CTab>

                <CTab v-show="activeLevel===1">
                  <template slot="title">Fila do Equipamento </template>
                  <EquipmentQueue :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></EquipmentQueue>
                </CTab>

              </CTabs>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  </div>
</template>

<script>
import Equipment from './Equipment'
import EquipmentAssociate from './associated/EquipmentAssociated.vue'
import EquipmentQueue from './queue/EquipmentQueue.vue'

export default {
  name: 'EquipmentContainer',
  components: {Equipment, EquipmentAssociate, EquipmentQueue},
  computed: {
    trailSelected() {
      if(this.pages.length > 0) {
        return this.getFirstPage.parentValue
      } else {
        return null 
      }
    },
    getFirstPage() {
      return this.pages[0];
    },    
    getLastPage() {
      return this.pages[this.pages.length-1];
    },
    activeLevel() {
      return this.pages.length
    }
  },
  data() {
    return {
      activeTab: 0,
      hierarchyLevel: {},
      pages: [],
      tabs:[
        {"parentName":"Equipment","tabIndex":0},
        {"parentName":"EquipmentAssociate","tabIndex":1},
      ]
    }
  },
  methods: {
    setTabIndex(payload){
      let tab = this.tabs.filter(i => (i.parentName === payload.parentName));
      this.activeTab = tab[0].tabIndex
    },
    reset(payload) {
      if(payload === 0) {
        this.pages = []
      } else {
        this.pages.splice(payload);
        this.setTabIndex(this.getLastPage)
      } 
    }
  },
  watch: {
    hierarchyLevel: {
      immediate: true,
      handler(hierarchyLevel) {
        if (Object.entries(this.hierarchyLevel).length !== 0) {
          this.pages.push(hierarchyLevel)
          this.setTabIndex(hierarchyLevel)
        }
      },
    },
  }
}
</script>
