<template>
  <div class="px-2">
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4" v-if="showFilter">
        <Filtrate @filter-equipment-queue="filter = $event"></Filtrate>
      </CCol>
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="4">
                <CInput
                  id="equipment_queue_id"
                  readonly
                  v-model="record.equipment_queue_id">
                  <template #prepend-content
                    ><i class="fas fa-info-circle"
                  /></template>
                </CInput>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="4">
                <CInput
                  id="atendimento_id"
                  label="Atendimento"
                  :readonly="readOnly"
                  v-model="record.atendimento_id"
                />
              </CCol>
              <CCol sm="4">
                <CSelect
                  id="equipment_id"
                  label="Equipamento"
                  readonly
                  :options="equipmentOptions"
                  v-model="record.equipment_id"
                  @update:value="record.equipment_id = $event"
                />
              </CCol>
              <CCol sm="4">
                <CSelect
                  id="equipment_associated_id"
                  label="Equipamento Associado"
                  readonly
                  :options="equipmentOptions"
                  v-model="record.equipment_associated_id"
                  @update:value="record.equipment_associated_id = $event"
                />
              </CCol>


            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
import Filtrate from "./EquipmentQueueFilter";

export default {
  name: "EquipmentQueue",
  props: {
    parent: Object,
  },
  components: { Toolbars, Grid, Detail, Filtrate },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 8;
    },
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/equipment-queue",
        primaryKey: "equipment_queue_id",
        descriptionKey: "equipment_queue_id",
        config: {
          child: true,
          parentKey: "equipment_id",
          parentValue: "equipment_id",
          parentService: "find-all-by-equipment",
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: true,
          fields: 'default',
        },
        finds: []
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],

      //Variaveis Componente <Select>
      typeOptions: [],
      classificationOptions: [],
      statusOptions: [],
    };
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.equipmentOptions = fn.finds.findAllEquipmentByType(38);
      })
      this.afterInit()
    },
    afterInit() {
      fn.getRecords(this)
    },
    setInitialValues() {
      fn.setAction(this)
    }
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== null) {
          fn.setAction(this)
        }
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) { 
          fn.loadRecord(this)    
        }
      }
    },
    parent: {
      immediate: true,
      handler(parent) {
        if(parent!== undefined && parent.parentKey === this.COMPONENT.config.parentKey) {
          this.init()
        } else {
          fn.reset(this)
        }
      }
    }
  }
}
</script>
